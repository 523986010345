"use client"

import { ReactRenderer } from "@tiptap/react"
import tippy, { type Instance } from "tippy.js"
import {
  SuggestionList,
  type SuggestionListProps,
  type SuggestionListRef,
} from "./SuggestionList"
import type { MentionOptions } from "./extension/mention"

export type MentionType =
  | "image"
  | "signature"
  | "blocktext"
  | "inlinetext"
  | "inlinesentence"
  | "table"
  | "location"
  | "date"
  | "number"
  | "currency"

export const isBlockMention = (mentionType?: MentionType) =>
  mentionType &&
  ["blocktext", "signature", "image", "table"].includes(mentionType)

export const isInlineMention = (mentionType?: MentionType) =>
  !isBlockMention(mentionType)

export type MentionSuggestion<T extends string = string> = {
  id: T
  label: string
  /**
   * @default "text"
   */
  mentionType?: MentionType

  /**
   * category of the mention
   */
  category?: string

  /**
   * will be displayed in the suggestion list
   * @default ""
   */
  preview?: string

  /**
   * only primitive string values are allowed
   */
  payload?: string

  /**
   * will add an annotation to the mention
   */
  annotation?: {
    label: string
    message: string
    color: "primary" | "secondary" | "warning" | "error" | "info" | "success"
  }

  /**
   * variable will be hidden in the suggestion list
   */
  hidden?: boolean
}

/**
 * Workaround for the current typing incompatibility between Tippy.js and Tiptap
 * Suggestion utility.
 *
 * @see https://github.com/ueberdosis/tiptap/issues/2795#issuecomment-1160623792
 *
 * Adopted from
 * https://github.com/Doist/typist/blob/a1726a6be089e3e1452def641dfcfc622ac3e942/stories/typist-editor/constants/suggestions.ts#L169-L186
 */
const DOM_RECT_FALLBACK: DOMRect = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  x: 0,
  y: 0,
  toJSON() {
    return {}
  },
}

export function setupSuggestion(suggestions: MentionSuggestion[]) {
  const getClientRect = (clientRectGetter?: () => DOMRect | null) =>
    clientRectGetter?.() || DOM_RECT_FALLBACK

  return {
    items: async ({ query }): Promise<MentionSuggestion[]> => {
      return (
        suggestions
          .filter((item) => !item.hidden)
          // Find matching entries based on what the user has typed so far (after
          // the "char" symbol)
          .filter((item) =>
            item.label.toLowerCase().includes(query.toLowerCase()),
          )
      )
    },

    char: "@",

    render: () => {
      let component:
        | ReactRenderer<SuggestionListRef, SuggestionListProps>
        | undefined
      let popup: Instance | undefined

      return {
        onStart: (props) => {
          if (!props.clientRect) {
            return
          }

          component = new ReactRenderer(SuggestionList, {
            props,
            editor: props.editor,
          })

          popup = tippy("body", {
            getReferenceClientRect: getClientRect.bind(
              undefined,
              props.clientRect,
            ),
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: "manual",
            placement: "bottom-start",
          }).at(0)
        },

        onUpdate(props) {
          component?.updateProps(props)

          if (!props.clientRect) {
            return
          }

          popup?.setProps({
            getReferenceClientRect: getClientRect.bind(
              undefined,
              props.clientRect,
            ),
          })
        },

        onKeyDown(props) {
          if (props.event.key === "Escape") {
            popup?.hide()
            return true
          }

          if (!component?.ref) {
            return false
          }

          return component.ref.onKeyDown(props)
        },

        onExit() {
          popup?.destroy()
          component?.destroy()

          // Remove references to the old popup and component upon destruction/exit.
          // (This should prevent redundant calls to `popup.destroy()`, which Tippy
          // warns in the console is a sign of a memory leak, as the `suggestion`
          // plugin seems to call `onExit` both when a suggestion menu is closed after
          // a user chooses an option, *and* when the editor itself is destroyed.)
          popup = undefined
          component = undefined
        },
      }
    },
  } satisfies MentionOptions["suggestion"]
}
