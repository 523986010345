"use client"

import type {
  SuggestionKeyDownProps,
  SuggestionProps,
} from "@tiptap/suggestion"

import { Chip } from "@/components/ui/data-display/Chip"
import { List } from "@/components/ui/data-display/List"
import { ListItem } from "@/components/ui/data-display/ListItem"
import { ListItemButton } from "@/components/ui/data-display/ListItemButton"
import { ListItemIcon } from "@/components/ui/data-display/ListItemIcon"
import { ListItemText } from "@/components/ui/data-display/ListItemText"
import { Paper } from "@/components/ui/surfaces/Paper"
import {
  mdiCalendarToday,
  mdiCurrencyEur,
  mdiImage,
  mdiMapMarker,
  mdiNumeric,
  mdiSignatureFreehand,
  mdiTable,
  mdiText,
  mdiTextLong,
  mdiTextShort,
} from "@mdi/js"
import Icon from "@mdi/react"
import {
  Fragment,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react"
import type { MentionSuggestion } from "../suggestions"
import { Tooltip } from "@/components/ui/data-display/Tooltip"
import { ListSubheader } from "@/components/ui/data-display/ListSubheader"
import { useTranslation } from "@/i18n"
import de from "../messages/de.json"
import { assertUnreachable } from "@/utils/types/helpers"

export type SuggestionListRef = {
  onKeyDown: (props: SuggestionKeyDownProps) => boolean
}

export type SuggestionListProps = Pick<
  SuggestionProps<MentionSuggestion>,
  "items" | "command"
> &
  Partial<Omit<SuggestionProps<MentionSuggestion>, "items" | "command">> & {
    disablePaperWrapper?: boolean
  }

export const SuggestionList = forwardRef<
  SuggestionListRef,
  SuggestionListProps
>((props: SuggestionListProps, ref) => {
  const { t } = useTranslation(de)
  const { items, command, disablePaperWrapper } = props
  const [selectedIndex, setSelectedIndex] = useState(0)

  const selectItem = useCallback(
    (index: number) => {
      const suggestion = items.at(index)

      if (index >= items.length || !suggestion) {
        // Make sure we actually have enough items to select the given index. For
        // instance, if a user presses "Enter" when there are no options, the index will
        // be 0 but there won't be any items, so just ignore the callback here
        return
      }

      // Set all of the attributes of our Mention node based on the suggestion
      // data. The fields of `suggestion` will depend on whatever data you
      // return from your `items` function in your "suggestion" options handler.
      // Our suggestion handler returns `MentionSuggestion`s (which we've
      // indicated via SuggestionProps<MentionSuggestion>). We are passing an
      // object of the `MentionNodeAttrs` shape when calling `command` (utilized
      // by the Mention extension to create a Mention Node).
      const mentionItem: MentionSuggestion = {
        ...suggestion,
      }

      // there is currently a bug in the Tiptap SuggestionProps
      // type where if you specify the suggestion type (like
      // `SuggestionProps<MentionSuggestion>`), it will incorrectly require that
      // type variable for `command`'s argument as well (whereas instead the
      // type of that argument should be the Mention Node attributes). This
      // should be fixed once https://github.com/ueberdosis/tiptap/pull/4136 is
      // merged and we can add a separate type arg to `SuggestionProps` to
      // specify the type of the commanded selected item.
      command(mentionItem)
    },
    [command, items],
  )

  const upHandler = () => {
    setSelectedIndex((selectedIndex + items.length - 1) % items.length)
  }

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % items.length)
  }

  const enterHandler = () => {
    selectItem(selectedIndex)
  }

  useEffect(() => {
    if (items.length) {
      setSelectedIndex(0)
    }
  }, [items])

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === "ArrowUp") {
        upHandler()
        return true
      }

      if (event.key === "ArrowDown") {
        downHandler()
        return true
      }

      if (event.key === "Enter") {
        enterHandler()
        return true
      }

      return false
    },
  }))

  const itemsWithIndex = useMemo(
    () =>
      items.map((item, index) => ({
        ...item,
        index,
      })),
    [items],
  )
  const nonHiddenItems = useMemo(
    () => itemsWithIndex.filter((item) => !item.hidden),
    [itemsWithIndex],
  )
  const itemsGroupedByCategory: Record<
    string,
    (MentionSuggestion & { index: number })[]
  > = useMemo(
    () =>
      nonHiddenItems.reduce(
        (acc, item) => {
          if (!item.category) {
            return acc
          }

          if (!acc[item.category]) {
            acc[item.category] = []
          }

          acc[item.category].push(item)

          return acc
        },
        {} as Record<string, (MentionSuggestion & { index: number })[]>,
      ),
    [nonHiddenItems],
  )

  const getIconByMentionType = useCallback(
    (mentionType: MentionSuggestion["mentionType"]) => {
      switch (mentionType) {
        case "inlinesentence":
          return mdiText
        case "date":
          return mdiCalendarToday
        case "number":
          return mdiNumeric
        case "currency":
          return mdiCurrencyEur
        case "location":
          return mdiMapMarker
        case "table":
          return mdiTable
        case "blocktext":
          return mdiTextLong
        case "signature":
          return mdiSignatureFreehand
        case "image":
          return mdiImage
        case "inlinetext":
        case undefined:
          return mdiTextShort
      }
      return assertUnreachable(mentionType)
    },
    [],
  )

  const list = useMemo(
    () => (
      <List
        dense
        sx={{
          // In case there are contiguous stretches of long text that can't wrap:
          overflow: "hidden",
        }}
      >
        {Object.entries(itemsGroupedByCategory).flatMap(([category, items]) => [
          <ListSubheader key={category}>{category}</ListSubheader>,
          ...items.map((item) => (
            <ListItem key={item.id} disablePadding className="h-auto">
              <ListItemButton
                dense
                selected={item.index === selectedIndex}
                onClick={() => selectItem(item.index)}
              >
                <ListItemIcon>
                  <Icon
                    path={getIconByMentionType(item.mentionType)}
                    size={1}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={item.label}
                  secondary={
                    item.preview
                      ? t("suggestionList.preview", { preview: item.preview })
                      : undefined
                  }
                  secondaryTypographyProps={{
                    className:
                      "max-w-64 overflow-hidden whitespace-nowrap overflow-ellipsis",
                    variant: "caption",
                    color: "text.secondary",
                    sx: { maxWidth: 300 },
                  }}
                />
                {item.annotation?.label && (
                  <Tooltip title={item.annotation.message}>
                    <div>
                      <Chip
                        size="small"
                        color={item.annotation.color}
                        variant="filled"
                        label={item.annotation.label}
                      />
                    </div>
                  </Tooltip>
                )}
              </ListItemButton>
            </ListItem>
          )),
        ])}
      </List>
    ),
    [
      t,
      itemsGroupedByCategory,
      selectItem,
      selectedIndex,
      getIconByMentionType,
    ],
  )

  if (!items.length) {
    return null
  }

  return disablePaperWrapper ? list : <Paper elevation={5}>{list}</Paper>
})

SuggestionList.displayName = "SuggestionList"
