"use client"

import CharacterCount from "@tiptap/extension-character-count"
import { Color } from "@tiptap/extension-color"
import { Link } from "@tiptap/extension-link"
import { Placeholder } from "@tiptap/extension-placeholder"
import { TableCell } from "@tiptap/extension-table-cell"
import { TableHeader } from "@tiptap/extension-table-header"
import { TableRow } from "@tiptap/extension-table-row"
import { TextAlign } from "@tiptap/extension-text-align"
import { TextStyle } from "@tiptap/extension-text-style"
import { Underline } from "@tiptap/extension-underline"
import type { EditorProps } from "@tiptap/pm/view"
import StarterKit from "@tiptap/starter-kit"
import {
  LinkBubbleMenuHandler,
  ResizableImage,
  TableImproved,
} from "mui-tiptap"
import { useMemo } from "react"

import type { EditorOptions, Extensions } from "@tiptap/react"
import type { RichTextEditorVariant } from "."
import { BlockMention, InlineMention } from "./extension/mention"
import { Indent } from "./extension/indent"
import { setupSuggestion, type MentionSuggestion } from "./suggestions"

import "./extension/mention.css"

const CustomLinkExtension = Link.extend({
  inclusive: false,
})

export interface ExtensionProps {
  variant: RichTextEditorVariant
  placeholder?: string
  charLimit?: number
  suggestions?: MentionSuggestion[]
}

export const useEditor = (
  {
    variant = "document",
    placeholder = "",
    charLimit,
    suggestions,
  }: ExtensionProps = {
    variant: "document",
  },
): Pick<EditorOptions, "extensions" | "editorProps"> => {
  const extensions: Extensions = useMemo(
    () =>
      [
        TableImproved.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        TableCell,
        ...(charLimit
          ? [
              CharacterCount.configure({
                limit: charLimit,
              }),
            ]
          : []),
        ...(suggestions?.length
          ? [
              BlockMention.configure({
                renderText({ node }) {
                  return `${node.attrs.label ?? node.attrs.id}`
                },
                suggestion: setupSuggestion(suggestions),
              }),
              InlineMention.configure({
                renderText({ node }) {
                  return `${node.attrs.label ?? node.attrs.id}`
                },
                suggestion: setupSuggestion(suggestions),
              }),
            ]
          : []),
        Indent.configure(),
        Placeholder.configure({
          placeholder,
        }),
        StarterKit.configure({
          codeBlock: false,
          code: false,
          hardBreak: false,
          ...(variant === "document"
            ? {
                heading: {
                  levels: [1, 2, 3, 4],
                },
              }
            : {
                strike: false,
                horizontalRule: false,
                heading: false,
              }),
        }),
        TextStyle,
        Color,
        /**
         * Highlight color is not yet supported by our HTML 2 PDF renderer,
         * so we'll hide it for now.
         */
        // Highlight.configure({ multicolor: true }),
        Underline,
        ResizableImage.configure({
          allowBase64: true,
        }),
        TextAlign.configure({
          types:
            variant === "document" ? ["heading", "paragraph"] : ["paragraph"],
        }),
        CustomLinkExtension.configure({
          autolink: true,
          linkOnPaste: true,
          openOnClick: false,
        }),
        LinkBubbleMenuHandler,
      ] as Extensions,
    [charLimit, placeholder, suggestions, variant],
  )

  const editorProps: EditorProps = useMemo(() => ({}), [])

  return { extensions, editorProps } satisfies Pick<
    EditorOptions,
    "extensions" | "editorProps"
  >
}
