import DocumentIcon from "@/app/[locale]/(document)/_components/DocumentIcon"
import { isUserDataOfServiceProvider } from "@/app/[locale]/(user)/_utils"
import useAside from "@/app/_components/Aside/hooks/useAside"
import NotFoundError from "@/app/_components/NotFoundError"
import StaticBadge from "@/app/_components/StaticBadge"
import deApp from "@/app/_messages/de.json"
import { trpc } from "@/app/_trpc/client"
import { Divider } from "@/components/ui/data-display/Divider"
import { List } from "@/components/ui/data-display/List"
import { ListItemButton } from "@/components/ui/data-display/ListItemButton"
import { ListItemText } from "@/components/ui/data-display/ListItemText"
import { Typography } from "@/components/ui/data-display/Typography"
import { Box } from "@/components/ui/layout/Box"
import { Stack } from "@/components/ui/layout/Stack"
import { useTranslation } from "@/i18n"
import { getDayjsDateTimeFormat } from "@/utils/format"
import { LibConstants, useGetUserSessionData, type UserData } from "@/utils/lib"
import { replaceSlugs } from "@/utils/navigation"
import { useEditor } from "@/utils/richTextEditor"
import { assertUnreachable } from "@/utils/types/helpers"
import { mdiAlertCircle, mdiChat, mdiCheckCircle, mdiEmail } from "@mdi/js"
import Icon from "@mdi/react"
import { CircularProgress, type StackProps } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { useQueryClient } from "@tanstack/react-query"
import { getQueryKey } from "@trpc/react-query"
import type { inferRouterInputs, inferRouterOutputs } from "@trpc/server"
import { useIntersectionObserver } from "@uidotdev/usehooks"
import dayjs from "dayjs"
import { RichTextReadOnly } from "mui-tiptap"
import { useParams, useRouter } from "next/navigation"
import { useCallback, useEffect, useMemo } from "react"
import de from "../../_messages/de.json"
import type { NotificationRouter } from "@/server/types/server/communication/trpc/routers/notificationRouter"

type NotificationData =
  inferRouterOutputs<NotificationRouter>["list"]["results"][number]

export interface NotificationListProps extends StackProps {
  onClick?: () => void
}

export default function NotificationList({
  onClick = () => {},
  ...stackProps
}: NotificationListProps) {
  const queryClient = useQueryClient()
  const { t } = useTranslation(de)
  const [scrollEndRef, entry] = useIntersectionObserver<HTMLDivElement>({
    threshold: 0,
    root: null,
  })
  const isScrollEndInView = entry?.isIntersecting ?? false

  const { data: userData, error: errorUserData } = useGetUserSessionData()

  const notificationsQuery = trpc.notification.list.useInfiniteQuery(
    {
      limit: LibConstants.defaultLimit,
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
    },
  )
  const notifications = useMemo(
    () =>
      notificationsQuery.data?.pages.flatMap((page) => page.results ?? []) ??
      [],
    [notificationsQuery.data?.pages],
  )
  const loading = notificationsQuery.status === "pending"
  const isEmpty = !loading && notificationsQuery.data?.pages?.at(0)?.total === 0

  const acknowledgeMutation = trpc.notification.acknowledge.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getQueryKey(trpc.notification.list),
      })
      queryClient.invalidateQueries({
        queryKey: getQueryKey(trpc.notification.check),
      })
    },
  })

  const handleAcknowledge = useCallback(
    (payload: inferRouterInputs<NotificationRouter>["acknowledge"]) => {
      acknowledgeMutation.mutate(payload)
      onClick()
    },
    [acknowledgeMutation, onClick],
  )

  useEffect(() => {
    if (
      isScrollEndInView &&
      !notificationsQuery.isFetching &&
      notificationsQuery.hasNextPage
    ) {
      notificationsQuery.fetchNextPage()
    }
  }, [notificationsQuery, isScrollEndInView])

  if (notificationsQuery.error || errorUserData) {
    throw notificationsQuery.error ?? errorUserData
  }
  return (
    <Stack className={"overflow-y-auto h-full max-h-[32rem]"} {...stackProps}>
      {isEmpty && (
        <NotFoundError
          customMessage={t("notifictaions.popper.noNotifications")}
        />
      )}
      {!isEmpty && (
        <List
          className="py-0"
          dense
          sx={{
            minWidth: 300,
            maxWidth: stackProps.maxWidth,
          }}
        >
          {notifications.map((notification) => {
            const notificationType = notification.category

            switch (notificationType) {
              case "task_assigned":
                return (
                  <NotificationTaskListItem
                    key={notification.id}
                    notification={notification}
                    userData={userData}
                    onAcknowledge={handleAcknowledge}
                  />
                )
              case "chat_message_received":
              case "email_received":
                return (
                  <NotificationMessageListItem
                    key={notification.id}
                    notification={notification}
                    userData={userData}
                    onAcknowledge={handleAcknowledge}
                  />
                )
              case "workflow_run_failed":
              case "workflow_run_aborted":
                return (
                  <NotificationWorkflowListItem
                    key={notification.id}
                    notification={notification}
                    userData={userData}
                    onAcknowledge={handleAcknowledge}
                  />
                )
              default:
                return assertUnreachable(notificationType)
            }
          })}
          {!notificationsQuery.isFetching && (
            <div className="w-0 h-0" ref={scrollEndRef} />
          )}
        </List>
      )}
      {!isEmpty && notificationsQuery.isFetching && (
        <Box className="flex justify-center" p={4}>
          <CircularProgress color="info" />
        </Box>
      )}
    </Stack>
  )
}

function NotificationTaskListItem({
  notification,
  userData,
  onAcknowledge,
}: {
  notification: NotificationData
  userData?: UserData | null
  onAcknowledge?: (
    payload: inferRouterInputs<NotificationRouter>["acknowledge"],
  ) => void
}) {
  const { t: tApp } = useTranslation(deApp)
  const { t, tHtml } = useTranslation(de)
  const theme = useTheme()
  const { openAside } = useAside()

  const handleNotificationClick = useCallback(
    (notification: NotificationData) => {
      const taskId = notification?.task?.id

      if (taskId) {
        openAside("task", taskId)
      }

      onAcknowledge?.({
        id: notification.id,
        type: notification.category,
      })
    },
    [onAcknowledge, openAside],
  )

  return (
    <ListItemButton
      className="gap-4"
      data-testid="notification-item"
      onClick={handleNotificationClick.bind(null, notification)}
    >
      {notification.task?.document && (
        <DocumentIcon category={notification?.task?.document?.category} />
      )}
      {!notification.task?.document && (
        <Icon
          className="shrink-0"
          path={mdiCheckCircle}
          size={1}
          color={theme.palette.action.active}
        />
      )}
      <ListItemText
        primary={tHtml(
          notification?.task?.document
            ? "notifictaions.popper.notificationTitle.assignedTaskWithDocument"
            : "notifictaions.popper.notificationTitle.assignedTask",
          {
            name: notification?.task?.name ?? "",
            document: notification?.task?.document?.name ?? "",
          },
        )}
        secondary={
          <Stack
            direction="row"
            spacing={1}
            divider={<Divider flexItem orientation="vertical" />}
          >
            <Typography className="whitespace-nowrap" variant="inherit">
              {dayjs(notification.created_at).format(getDayjsDateTimeFormat())}
            </Typography>
            <Typography className="whitespace-nowrap" variant="inherit">
              {notification.case?.file_number}
            </Typography>
            <Typography
              className="whitespace-nowrap overflow-hidden text-ellipsis"
              variant="inherit"
            >
              {notification.case?.name ?? tApp("cases.noName")}
            </Typography>
          </Stack>
        }
      />
      {!notification.read && (
        <StaticBadge variant="primary" size="small" badgeContent={" "} />
      )}
    </ListItemButton>
  )
}

function NotificationMessageListItem({
  notification,
  userData,
  onAcknowledge,
}: {
  notification: NotificationData
  userData?: UserData | null
  onAcknowledge?: (
    payload: inferRouterInputs<NotificationRouter>["acknowledge"],
  ) => void
}) {
  const { t: tApp } = useTranslation(deApp)
  const { t } = useTranslation(de)
  const theme = useTheme()
  const router = useRouter()
  const params = useParams()
  const { openAside, asideSearch } = useAside()
  const { extensions, editorProps } = useEditor({
    variant: "chat",
  })

  const handleNotificationClick = useCallback(
    (notification: NotificationData) => {
      const caseId = notification?.case?.id
      const conversationId =
        notification?.chat_message?.conversation_id ??
        notification.email?.conversation_id
      const userOfServiceProvider = isUserDataOfServiceProvider(userData)
      const userRoleRoute = userOfServiceProvider ? "cardealer" : "lawyer"
      const userRoleRouteParam = userOfServiceProvider
        ? "cardealerId"
        : "lawyerId"

      if (!caseId || !conversationId) {
        return
      }

      if (notification.category === "chat_message_received") {
        // TODO: make chat message openable in aside
        const newUrl = replaceSlugs(
          `/[locale]/${userRoleRoute}/[${userRoleRouteParam}]/cases/[caseId]/messages/[conversationSlug]`,
          { ...params, caseId, conversationSlug: conversationId },
          asideSearch,
        )

        router.push(newUrl)
      } else if (
        notification.category === "email_received" &&
        notification.email?.id
      ) {
        openAside("email", notification.email.id)
      }

      onAcknowledge?.({
        id: notification.id,
        type: notification.category,
      })
    },
    [onAcknowledge, openAside, asideSearch, userData, params, router],
  )

  return (
    <ListItemButton
      className="gap-4"
      data-testid="notification-item"
      onClick={handleNotificationClick.bind(null, notification)}
    >
      {notification.category === "email_received" && (
        <Icon
          className="shrink-0"
          path={mdiEmail}
          size={1}
          color={theme.palette.action.active}
        />
      )}
      {notification.category === "chat_message_received" && (
        <Icon
          className="shrink-0"
          path={mdiChat}
          size={1}
          color={theme.palette.action.active}
        />
      )}
      <ListItemText
        primary={
          notification.chat_message?.content ? (
            <RichTextReadOnly
              content={notification.chat_message?.content ?? ""}
              extensions={extensions}
              editorProps={editorProps}
            />
          ) : (
            notification.email?.subject
          )
        }
        secondary={
          <Stack
            direction="row"
            spacing={1}
            divider={<Divider flexItem orientation="vertical" />}
          >
            <Typography className="whitespace-nowrap" variant="inherit">
              {dayjs(notification.created_at).format(getDayjsDateTimeFormat())}
            </Typography>
            <Typography className="whitespace-nowrap" variant="inherit">
              {notification.case?.file_number}
            </Typography>
            <Typography
              className="whitespace-nowrap overflow-hidden text-ellipsis"
              variant="inherit"
            >
              {notification.case?.name ?? tApp("cases.noName")}
            </Typography>
          </Stack>
        }
      />
      {!notification.read && (
        <StaticBadge variant="primary" size="small" badgeContent={" "} />
      )}
    </ListItemButton>
  )
}

function NotificationWorkflowListItem({
  notification,
  userData,
  onAcknowledge,
}: {
  notification: NotificationData
  userData?: UserData | null
  onAcknowledge?: (
    payload: inferRouterInputs<NotificationRouter>["acknowledge"],
  ) => void
}) {
  const { t: tApp } = useTranslation(deApp)
  const { t, tHtml } = useTranslation(de)
  const theme = useTheme()
  const router = useRouter()
  const params = useParams()
  const { asideSearch } = useAside()

  const handleNotificationClick = useCallback(
    (notification: NotificationData) => {
      const caseId = notification?.case?.id
      const userOfServiceProvider = isUserDataOfServiceProvider(userData)
      const userRoleRoute = userOfServiceProvider ? "cardealer" : "lawyer"
      const userRoleRouteParam = userOfServiceProvider
        ? "cardealerId"
        : "lawyerId"

      if (!caseId) {
        return
      }

      // TODO: make workflow step openable in aside
      const newUrl = replaceSlugs(
        `/[locale]/${userRoleRoute}/[${userRoleRouteParam}]/cases/[caseId]/workflows`,
        { ...params, caseId },
        asideSearch,
      )

      onAcknowledge?.({
        id: notification.id,
        type: notification.category,
      })

      router.push(newUrl)
    },
    [onAcknowledge, asideSearch, userData, params, router],
  )

  return (
    <ListItemButton
      className="gap-4"
      data-testid="notification-item"
      onClick={handleNotificationClick.bind(null, notification)}
    >
      <Icon
        className="shrink-0"
        path={mdiAlertCircle}
        size={1}
        color={theme.palette.action.active}
      />
      <ListItemText
        primary={
          notification.category === "workflow_run_aborted"
            ? tHtml(
                "notifictaions.popper.notificationTitle.workflowRunFailed",
                {
                  name:
                    notification.workflow_run?.step?.name ??
                    tApp("workflows.noName"),
                },
              )
            : tHtml(
                "notifictaions.popper.notificationTitle.workflowRunAborted",
                {
                  name:
                    notification.workflow_run?.step?.name ??
                    tApp("workflows.noName"),
                },
              )
        }
        secondary={
          <Stack
            direction="row"
            spacing={1}
            divider={<Divider flexItem orientation="vertical" />}
          >
            <Typography className="whitespace-nowrap" variant="inherit">
              {dayjs(notification.created_at).format(getDayjsDateTimeFormat())}
            </Typography>
            <Typography className="whitespace-nowrap" variant="inherit">
              {notification.case?.file_number}
            </Typography>
            <Typography
              className="whitespace-nowrap overflow-hidden text-ellipsis"
              variant="inherit"
            >
              {notification.case?.name ?? tApp("cases.noName")}
            </Typography>
          </Stack>
        }
      />
      {!notification.read && (
        <StaticBadge variant="primary" size="small" badgeContent={" "} />
      )}
    </ListItemButton>
  )
}
