"use client"

import { useTranslation } from "@/i18n"
import { mdiAt } from "@mdi/js"
import Icon from "@mdi/react"
import {
  MenuButton,
  type MenuButtonProps,
  useRichTextEditorContext,
} from "mui-tiptap"
import de from "@/app/_messages/de.json"
import { isBlockMention, type MentionSuggestion } from "../suggestions"
import useMenu from "@/components/ui/navigation/Menu/useMenu"
import { Menu } from "@/components/ui/navigation/Menu"
import { SuggestionList } from "../SuggestionList"
import {
  PluginKeyBlockMention,
  PluginKeyInlineMention,
} from "../extension/mention"

export type MenuButtonSuggestionProps = Partial<MenuButtonProps> & {
  suggestions: MentionSuggestion[]
}

export default function MenuButtonSuggestion(props: MenuButtonSuggestionProps) {
  const { t } = useTranslation(de)
  const editor = useRichTextEditorContext()
  const { openMenu, closeMenu, menuProps } = useMenu()

  const handleCommand = (props: MentionSuggestion) => {
    closeMenu()
    editor?.commands.insertContent([
      {
        type: isBlockMention(props.mentionType)
          ? PluginKeyBlockMention
          : PluginKeyInlineMention,
        attrs: props,
      },
      {
        type: "text",
        text: " ",
      },
    ])
  }

  return (
    <>
      <MenuButton
        tooltipLabel={t("rte.extension.suggestion.tooltip")}
        tooltipShortcutKeys={["@"]}
        IconComponent={SuggestionIcon}
        disabled={!editor?.isEditable}
        onClick={openMenu}
        {...props}
      />
      {editor && (
        <Menu
          {...menuProps}
          MenuListProps={{
            dense: true,
          }}
        >
          <SuggestionList
            editor={editor}
            items={props.suggestions}
            command={handleCommand}
            disablePaperWrapper
          />
        </Menu>
      )}
    </>
  )
}

function SuggestionIcon() {
  return <Icon path={mdiAt} size={0.833 /* 20px */} />
}
