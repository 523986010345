import {
  type Command,
  type KeyboardShortcutCommand,
  Extension,
} from "@tiptap/core"
import type { Node } from "prosemirror-model"
import {
  TextSelection,
  AllSelection,
  type Transaction,
} from "prosemirror-state"

type IndentOptions = {
  types: string[]
  indentLevels: number[]
  defaultIndentLevel: number
}

declare module "@tiptap/core" {
  interface Commands {
    indent: {
      /**
       * Set the indent attribute
       */
      indent: () => Command
      /**
       * Unset the indent attribute
       */
      outdent: () => Command
    }
  }
}

export function clamp(val: number, min: number, max: number): number {
  if (val < min) {
    return min
  }
  if (val > max) {
    return max
  }
  return val
}

export enum IndentProps {
  min = 0,
  max = 210,

  more = 30,
  less = -30,
}

export function isBulletListNode(node: Node): boolean {
  return node.type.name === "bullet_list"
}

export function isOrderedListNode(node: Node): boolean {
  return node.type.name === "order_list"
}

export function isTodoListNode(node: Node): boolean {
  return node.type.name === "todo_list"
}

export function isListNode(node: Node): boolean {
  return (
    isBulletListNode(node) || isOrderedListNode(node) || isTodoListNode(node)
  )
}

function setNodeIndentMarkup(
  trx: Transaction,
  pos: number,
  delta: number,
): Transaction {
  if (!trx.doc) {
    return trx
  }

  const node = trx.doc.nodeAt(pos)
  if (!node) {
    return trx
  }

  const minIndent = IndentProps.min
  const maxIndent = IndentProps.max

  const currentIndent = Number.parseInt(node.attrs.indent) || 0
  const indent = clamp(currentIndent + delta, minIndent, maxIndent)

  if (indent === node.attrs.indent) {
    return trx
  }

  const nodeAttrs = {
    ...node.attrs,
    indent,
  }

  return trx.setNodeMarkup(pos, node.type, nodeAttrs, node.marks)
}

function updateIndentLevel(_trx: Transaction, delta: number): Transaction {
  let trx = _trx
  const { doc, selection } = trx

  if (!doc || !selection) {
    return trx
  }

  if (
    !(selection instanceof TextSelection || selection instanceof AllSelection)
  ) {
    return trx
  }

  const { from, to } = selection

  doc.nodesBetween(from, to, (node, pos) => {
    const nodeType = node.type

    if (nodeType.name === "paragraph" || nodeType.name === "heading") {
      trx = setNodeIndentMarkup(trx, pos, delta)
      return false
    }
    if (isListNode(node)) {
      return false
    }
    return true
  })

  return trx
}

export const Indent = Extension.create<IndentOptions>({
  name: "indent",

  addOptions() {
    return {
      types: ["heading", "paragraph"],
      indentLevels: [0, 30, 60, 90, 120, 150, 180, 210],
      defaultIndentLevel: 0,
    }
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          indent: {
            default: this.options.defaultIndentLevel,
            renderHTML: (attributes) => {
              const indent =
                typeof attributes.indent === "object" && "indent" in attributes
                  ? attributes.indent.indent
                  : typeof attributes.indent === "number"
                    ? attributes.indent
                    : 0

              return {
                style: `margin-left: ${indent}px;`,
              }
            },
            parseHTML: (element) => ({
              indent:
                Number.parseInt(element.style.marginLeft) ||
                this.options.defaultIndentLevel,
            }),
          },
        },
      },
    ]
  },

  addCommands() {
    return {
      indent:
        () =>
        ({ tr, state, dispatch }) => {
          const { selection } = state
          tr = tr.setSelection(selection)
          tr = updateIndentLevel(tr, IndentProps.more)

          if (tr.docChanged) {
            if (dispatch) {
              dispatch(tr)
            }
            return true
          }

          return false
        },
      outdent:
        () =>
        ({ tr, state, dispatch }) => {
          const { selection } = state
          tr = tr.setSelection(selection)
          tr = updateIndentLevel(tr, IndentProps.less)

          if (tr.docChanged) {
            if (dispatch) {
              dispatch(tr)
            }
            return true
          }

          return false
        },
    }
  },

  addKeyboardShortcuts() {
    return {
      Tab: () => {
        if (
          !(
            this.editor.isActive("bulletList") ||
            this.editor.isActive("orderedList")
          )
        ) {
          this.editor.commands.indent()
        }
        this.editor.commands.sinkListItem("listItem")
        return true
      },
      "Shift-Tab": () => {
        if (
          !(
            this.editor.isActive("bulletList") ||
            this.editor.isActive("orderedList")
          )
        ) {
          this.editor.commands.outdent()
        }
        this.editor.commands.sinkListItem("listItem")
        return true
      },
    }
  },
})
