"use client"

import de from "@/app/_messages/de.json"
import { useTranslation } from "@/i18n"
import { useTheme } from "@mui/material/styles"

export interface CharCountProps {
  count: number
  limit: number
}

export function CharCount({ count, limit }: CharCountProps) {
  const { t } = useTranslation(de)
  const theme = useTheme()
  const charLimitPercentage = Math.round((100 / limit) * count)

  return (
    <div className={"flex flex-row gap-2 items-center px-3 py-3"}>
      <svg
        role="img"
        aria-label={t("rte.characterCount", {
          count: `${count}/${limit}`,
        })}
        height="20"
        width="20"
        viewBox="0 0 20 20"
      >
        <circle
          className="fill-stone-100"
          r="10"
          cx="10"
          cy="10"
          fill={theme.palette.action.disabledBackground}
        />
        <circle
          r="5"
          cx="10"
          cy="10"
          fill="transparent"
          stroke={
            count >= limit
              ? theme.palette.warning.main
              : theme.palette.info.main
          }
          strokeWidth="10"
          strokeDasharray={`calc(${charLimitPercentage} * 31.4 / 100) 31.4`}
          transform="rotate(-90) translate(-20)"
        />
        <circle r="6" cx="10" cy="10" fill="white" />
      </svg>

      <div className="text-stone-400">
        {t("rte.characterCount", {
          count: `${count}/${limit}`,
        })}
      </div>
    </div>
  )
}
