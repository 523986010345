"use client"

import type { FC } from "react"
import {
  ListItemButton as MuiListItemButton,
  type ListItemButtonProps,
} from "@mui/material"

export const ListItemButton: FC<ListItemButtonProps> = (props) => (
  <MuiListItemButton {...props} />
)
